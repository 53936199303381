<template>
  <div class="navmb">
      <div class="flex flexa">
          <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
              <Back />
          </el-icon> &nbsp; &nbsp;
          <span class="">海量资讯</span>
      </div>
  </div>
    <div class="navmbs">
        <el-tabs v-model="param.type" @tab-change="getList" style="margin-bottom: 0px;" >
          <el-tab-pane label="最新公告" name="1"></el-tab-pane>
          <el-tab-pane label="产品动态" name="2"></el-tab-pane>
        </el-tabs>
    </div>
    <div class="bgf">
        <el-table :data="list" style="width: 100%" v-loading="loading">
          <el-table-column
            fixed
            label="问题概要"
            class-name="padl20 padr20"
            :show-overflow-tooltip="{ effect: 'light' }"
            min-width="234"
          >
            <template #="{ row }">
              <div class="flex padr14">
                <router-link class="bllk col279 block text1" :to="`/news/detail/${ row.id }`">{{ row.title }}</router-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="createTime"
            label="上次更新时间"
            :show-overflow-tooltip="{ effect: 'light' }"
          >
          </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
          <p class="font14 cola4">共 {{ total }} 条</p>
          <el-pagination
            v-model:currentPage="param.pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="param.pageSize"
            layout="sizes, prev , jumper , next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
</template>
<script>
import { Back } from '@element-plus/icons-vue'
export default {
    components: {
        Back,
    },
    data() {
        return {
          param: {
            type: '1',
            pageNumber: 1,
            pageSize: 10,

          },
          loading: false,
          list: [],
          total: 0,

        }
    },
    methods: {
      handleCurrentChange(val) {
        this.param.pageNumber = val
        this.getList()
      },
      handleSizeChange(val) {
        this.param.pageSize = val
        this.getList()
      },
      async getList() {
        try {
          this.loading = true
          const { records, total } = await this.$api.base.announcementDynamics(this.param)
          this.total = total
          this.list = records
        } catch(e) {
          return console.log(e)
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.getList()
    }
}
</script>
<style scoped lang="scss">
.el-tabs__header {
  margin-bottom: 0
}
</style>